body, html {
    height: 100%;
    margin: 0;
    background: #FFF1E5;
}

header, main, footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin: 0 auto;
    max-width: 1000px;
    padding: 20px;
}

h1, h2, h3, h4, h5, h6, p {
    color: #e76f51; /* Slightly darkened color for better contrast */
}

h1, h2, h3, h4, h5, h6 {
    font-size: 4em;
}

p {
    font-size: 1.8em;
}

img {
   object-fit: cover;
   width: 100%;
   height: 100%;
}

.initial-content h1, .initial-content h2 {
    margin-bottom: 20px;
}

.flyr-icon {
    margin-bottom: 30px;
    display: block;
    margin: 0 auto;
    width: 200px;
}

.initial-content {
    padding-top: 40px;  /* Add top padding */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20vh;
}

.content-section {
    display: flex;
    align-items: center;
    gap: 100px;
    margin-top: 100px;
    margin-bottom: 20vh;
}

.content-section img, .content-section .section-heading {
    flex: 1;
}

.section-a img {
    order: 0;
}

.section-c img {
    order: 0;
}

.section-b img {
    order: 2;
}

.section-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.section-primary-heading {
    font-size: 2.5em;
    margin-bottom: 0.5em;
}

.section-secondary-heading {
    font-size: 1.8em;
    opacity: 0.9;
    margin-top: 0;
}

@media only screen and (max-width: 600px) {
    main {
      padding: 10px;
    }
    
    .content-section {
        flex-direction: column;
    }

    .section-heading {
        margin-bottom: 20px;
    }

    .section-a img, .section-b img, .section-c img {
        order: 1;
    }
}

@media only screen and (min-width: 1024px) {
    .content-section img {
        /* Reduce the image width to 70% of its original size */
        flex: 0.6;
        max-width: 60%;
    }

    .content-section .section-heading,
    .content-section .section-text {
        /* Allow the text content to take up the remaining space */
        flex: 1.4;
    }
}

/* Style for both buttons */
.custom-style {
    width: 60%; /* Mobile optimized width */
    height: auto;
    transition: transform 0.3s ease;
    margin: 5px 0; /* Vertical spacing for mobile layout */
    display: block; /* To ensure each button takes the full width on mobile */
}

.custom-style:hover {
    transform: scale(1.05); 
}

/* If the package uses images inside the button, you might want to adjust the image styles */
.custom-style img {
    width: 25%; 
    height: auto; 
    display: block;
}

/* Styles for Tablet */
@media only screen and (min-width: 601px) and (max-width: 1023px) {
    .custom-style {
        width: 40%; /* Adjust width for tablets */
        margin: 0 10px; /* Horizontal spacing for tablet layout */
        display: inline-block; /* Revert back to inline block to ensure they are side by side */
    }
}

/* Styles for Desktop */
@media only screen and (min-width: 1024px) {
    .custom-style {
        width: 170px; /* Fixed width for desktops */
    }
}

.buttons-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap; /* To ensure buttons wrap to next line if space is limited */
    gap: 20px;
}

.support-email-container {
    margin-top: 20px; 
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.support-email-container span {
    font-size: 20px; 
    margin: 0;
    color: #e76f51;
    opacity: 0.9; /* Match opacity of 'No folding, no bundling. Just hustle.' */
}

.support-email {
    font-size: 20px; 
    color: #e76f51;
    text-decoration: underline;
    margin-top: 5px;
    opacity: 0.9;
}
